<template>
    <el-card>
      <el-alert
          title="头像更新请重新登录"
          type="warning">
      </el-alert>

        <el-tabs v-model="activeName">
            <el-tab-pane label="当前信息" name="first">
              <el-form ref="ruleForm" :rules="rules" :model="userInfo" label-width="120px">
                <el-form-item label="用户账号">
                  <el-input v-model="userInfo.username" size="mini" disabled></el-input>
                </el-form-item>

                <!--<el-form-item label="用户昵称">-->
                <!--  <el-input v-model="userInfo.nickname" size="mini"></el-input>-->
                <!--</el-form-item>-->

                <el-form-item label="用户头像">
                  <!--<el-avatar :size="60" src="https://empty" @error="errorHandler">-->
                  <!--  <img src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"/>-->
                  <!--</el-avatar>-->
                  <div @click="selectImages">
                    <el-avatar v-if="userInfo.head_img" :src="userInfo.head_img"></el-avatar>
                    <div v-else class="head_img">
                      <i class="el-icon-camera-solid"></i>
                    </div>
                  </div>
                </el-form-item>

                <el-form-item label="原密码" prop="oldPassword">
                  <el-input placeholder="请输入原密码" v-model="userInfo.oldPassword" size="mini"></el-input>
                </el-form-item>

                <el-form-item label="新密码" prop="password">
                  <el-input placeholder="请输入密码" v-model="userInfo.password" size="mini" show-password></el-input>
                </el-form-item>

                <el-form-item label="确认新密码" prop="password2">
                  <el-input placeholder="请输入密码" v-model="userInfo.password2" size="mini" show-password></el-input>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" size="mini" @click="onSave">{{$t('button.save')}}</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <!--<el-tab-pane label="修改密码" name="second"></el-tab-pane>-->
        </el-tabs>



        <!--{{userData.username}}-->
        <!--{{userData}}-->

      <el-dialog :visible.sync="dialogVisible" :title="$t('tips.select')">
        <select-image
            :multiple="multiple"
            :active="false"
            @select="getImages"
            @close="dialogVisible = false">
        </select-image>
      </el-dialog>
    </el-card>
</template>

<script>
import { getUserData } from '@/utils/auth';
import { getUserInfo, own } from "@/api/os/admin";
import selectImage from '@/components/Images';

export default {
    data() {
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else {
            if (this.userInfo.password2 !== '') {
              this.$refs.ruleForm.validateField('password2');
            }
            callback();
          }
        };
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.userInfo.password) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
        return {
            activeName: 'first',
            // userData: getUserData(),
            userData: {},
            userInfo: {
              username: '',
              nickname: '',
              password: '',
              password2: '',
              oldPassword: '',
              head_img: ''
            },

            dialogVisible: false,
            multiple: false,
            rules: {
              oldPassword: [
                { required: true, message: '请输入原密码', trigger: 'blur' },
              ],
              password: [
                { required: true, message: '请输入', trigger: 'blur' },
                { validator: validatePass, trigger: 'blur' }
              ],
              password2: [
                { required: true, message: '请输入', trigger: 'blur' },
                { validator: validatePass2, trigger: 'blur' }
              ]
            }
        }
    },
    components: {
      selectImage
    },
    created() {
      this.get();
    },
    methods: {
      get() {
        getUserInfo().then(res => {
          if(res.data.data) {
            let userData = res.data.data
            this.userData = userData
            this.userInfo = {
              username: userData.username,
              nickname: userData.nickname,
              password: '',
              password2: '',
              oldPassword: '',
              head_img: userData.userData.head_img
            }
          }
        })
      },
      selectImages() {
        this.dialogVisible = true
      },
      getImages(data) {
        this.dialogVisible = false;
        this.userInfo.head_img = data
      },

      onSave() {
        this.$refs['ruleForm'].validate((valid) => {
          if (valid) {
            own({
              password: this.userInfo.password,
              oldPassword: this.userInfo.oldPassword,
              head_img: this.userInfo.head_img
            }).then(res => {
              if(res.data.code == 1) {
                this.$message.success(this.$t('tips.success'))
              }
            })
          }
        })
      }
    }
}
</script>

<style lang="less" scoped>
.head_img{
  background: #fff;
  border: 1px dashed #dcdee2;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  width: 58px; height: 58px; line-height: 58px;
}
</style>